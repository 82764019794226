
// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.container {
  max-width:855px;
}

#confirmationForm .form-group {
  margin-bottom:5px;
}
legend, .form-group, label {
  font-weight: 300;
}

option {
  font-size:12px;
}

body {
  padding:0;
  margin:0;
}
.row {
  margin-left:-30px;
}

#overviewCost .form-group {
  margin-bottom:0px;
}

.productPreviewImage img {
  width:200px;
}
.productPreviewImageDescription {
  max-width:300px;
  float:left;
  margin-left:20px;
}

.shipment_io_box {
  font-weight:300;
  color:#636b6f;

  strong {
    color:#636b6f;
    font-weight:600;
  }
}

.form-horizontal .control-label {
  text-align: left;
}

.container {
  margin-left:0;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 15px;
}

@media all and(max-width: 800px) {
  .container {
    padding-left:30px;
  }
  .row {
    margin-right:0;
  }
}